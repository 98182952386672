@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
    overflow-x: hidden;
}

ul{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
}

a {
    text-decoration: none;
}

.ActiveNavButton {
    border: transparent !important;
    background: rgba(255, 255, 255, 0.2) !important;
}

.ActiveNavButton:hover {
    background: rgba(103, 103, 103, 0.2) !important;
}

/* Home Page portals zoom affect */
.zoom-parent .zoom-image {
    transform: scale(1.05);
    transition: transform 1s ease-in-out;
}

.map{
    overflow: hidden;
    display: block;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 1450px) {
        width: 400px;
        height: 400px;
    }
    @media (max-width: 1250px) {
        width: 300px;
        height: 300px;
    }
    @media (max-width: 1100px) {
        width: 400px;
        height: 400px;
    }
    @media (max-width: 500px) {
        width: 300px;
        height: 300px;
    }
}







